<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="shipments" v-model:filters="filters1" v-model:selection="selectedRow"
                    :metaKeySelection="false" :filters="filters1" filterDisplay="menu" dataKey="id"
                    :globalFilterFields="['shipmentno']" scrollable :tableStyle="styleHeight" :scrollHeight="sHeight"
                    responsiveLayout="scroll" :loading="loading" :lazy="true" :paginator="true" :rows="pagesize"
                    :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:25%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入入库编号模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :showFilterMenu="col.showFilterMenu" :showFilterMatchModes="col.showFilterMenu"
                        :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline || col.isimage" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.shipmentno"
                                @click="openShipment(slotProps.data)" class="p-button-link" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                            <Image v-if="col.isimage" :src="getFilePatch(slotProps.data[col.field])" width="50"
                                preview />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :closeParentMehod="closeMaximizable"
                :childNewDataConfig="childNewDataConfig" :childDelDataConfig="childDelDataConfig"
                :importAddQueryConditionMethod="importAddQueryCondition" :dropOtherConfig="dropOtherConfig"
                :importAddColsMethod="importAddCols" :loadDataAfterMethod="loadDataAfter"
                :childTableFooter="childTableFooter" :CellEditCompleteMethod="cellEditComplete"
                :childaloneshowConfig="childaloneshowConfig" :CellEditCompleteExpandeMethod="cellEditCompleteExpande"
                :childExpandePageConfig="childExpandeConfig" :pageJsonConfig="pageJsonConfig"
                :CellEditInit="productCellEditInit" :childCreateBeforeMethod="childCreateBefore"
                :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
</template>

<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import '../../../js/DateEx.js'
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import shipmentPageJson from "@/data/shipmentConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '699';
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                pageJsonConfig: {},
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                position: 'bottomleft',
                flowId: '',
                startStateId: '',
                currentState: '流程信息',
                volumeratio: 100000,
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                currentRow: {
                    bindpage: 'shipment',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Shipment',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                currencyDict: [],
                childNewDataConfig: {
                    products: false,
                    files: true,
                    cost: true,
                },
                childDelDataConfig: {
                    products: true,
                    files: true,
                    cost: true,
                },
                flowhistoryJson: null,
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '草稿列表',
                selectedKey: {
                    699: true
                },
                expandedKey: null,
                childExpandeConfig: {},
                shipments: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '出运操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '新建出运',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createShipment();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delShipment();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                dropOtherConfig: {
                    purchasecurrency: 'purchaseexchangerate',
                    purchasecurrencyfun: (childelements, v_val, v_dictsource) => {
                        this.purchasecurrencyChange(childelements, v_val, v_dictsource);
                    },
                    fobcurrency: 'fobexchangerate',
                    fobcurrencyfun: (childelements, v_val, v_dictsource) => {
                        this.fobcurrencyChange(childelements, v_val, v_dictsource);
                    },
                    cost: {
                        currency: 'exchangerate'
                    },
                    sharingcostcurrency: 'sharingcostexchangerate',
                    storagewarehousefun: (childelements, v_val, v_dictsource, v_allmainelements) => {
                        this.storagewarehouseChange(childelements, v_val, v_dictsource, v_allmainelements);
                    },
                },
                childaloneshowConfig: {
                    cost: '1'
                },
                childTableFooter: {
                    cost: {
                        costtype: '合计',
                        costamount: 0,
                        invoiceamount: 0,
                    },
                    products: {
                        factoryname: '合计',
                        quantity: 0,
                        sharingcost: 0,
                    }
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getshipment(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0]['childExpandeConfig'];
                    if (data.page['region_3']['left']['element'][0]['volumeratio']) {
                        this.volumeratio = Number(data.page['region_3']['left']['element'][0]['volumeratio']);
                    }
                    this.initFilters1();
                    this.loadMenuTree();
                    this.loadFlowInfo();
                    CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this
                        .setloadDictFromCache);
                    this.loadUserAuth();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('698', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '698',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;

                        var createshipmentflag = this.$route.query['createshipment'];
                        if (createshipmentflag) {
                            this.createShipment();
                        }
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '草稿列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'shipmentdraftbox',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'shipmentno',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{shipmentlist(where:" + JSON.stringify(listwhere) +
                    "){id shipmentno signingdate shippingway shippingwayname purchasecurrency purchasecurrencyname purchaseexchangerate fobcurrency fobcurrencyname fobexchangerate rateprofit instorage operator approvestatus storename approvestatusname flowid workflowid boxid stateid currentstatename }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.shipments = jsonData.data.shipmentlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            createShipment() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateShipment')) {
                    MessageTip.warnmsg('请联系管理员开通新建出运权限');
                    return;
                }
                this.recordsubject = '新建出运';
                this.currentRow = {
                    bindpage: 'shipment',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Shipment',
                    boxid: '',
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.displayMaximizable = true;
            },
            delShipment() {
                this.$confirm.require({
                    message: '确认要删除?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        if (!this.selectedRow || this.selectedRow.length == 0) {
                            MessageTip.warnmsg('请选择要删除出运单');
                            return;
                        }
                        var shipmentIds = [];
                        for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                            shipmentIds.push({
                                "id": this.selectedRow[k1]["id"]
                            });
                        }
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "delete{shipmentlist(o:" + JSON.stringify(shipmentIds) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                this.selectedRow = null;
                                this.loadData(1);
                                MessageTip.successmsg('删除成功');
                            } else {
                                MessageTip.warnmsg('删除失败');
                            }
                        });
                    },
                    reject: () => {}
                });
            },
            openShipment(v_data) {
                this.currentState = '流程信息-当前环节:' + v_data.currentstatename;
                this.recordsubject = '出运单' + v_data.shipmentno;
                this.currentRow = {
                    bindpage: 'shipment',
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: 'Shipment',
                    boxid: v_data.boxid,
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.displayMaximizable = true;
            },
            importAddQueryCondition(v_listwhere) {
                v_listwhere.conditions.push({
                    name: 'purchasetoship',
                    value: '',
                    operation: '=',
                });
                var mainDataObj = this.$refs.universalpage.getAllMainElements();
                console.log('importAddQueryCondition...');
                console.log(mainDataObj);
                var storeid = '';
                for (var i = 0; i < mainDataObj.length; i++) {
                    if (mainDataObj[i]['name'] == 'storeid') {
                        if (mainDataObj[i]['val'] == '' || mainDataObj[i]['val'] == '-1') {
                            MessageTip.warnmsg('请先选择平台');
                            v_listwhere.conditions.push({
                                name: 'err',
                                value: '',
                                operation: '',
                            });
                        } else {
                            storeid = mainDataObj[i]['val'];
                        }
                    }
                }
                if (storeid != '') {
                    v_listwhere.conditions.push({
                        name: 'storeid',
                        value: storeid,
                        operation: '',
                    });
                }
                return v_listwhere;
            },
            importAddCols(v_cols, v_graphqlFeild) {
                console.log('importAddCols:1');
                console.log(v_cols);
                v_graphqlFeild = v_graphqlFeild + ' factoryname purchaseno currency';
                v_cols.splice(0, v_cols.length);
                v_cols.push({
                    name: 'sku',
                    label: 'SKU',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                v_cols.push({
                    name: 'canshipquantity',
                    label: '可出运数量',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                v_cols.push({
                    name: 'purchaseno',
                    label: '采购编号',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                v_cols.push({
                    name: 'factoryname',
                    label: '工厂简称',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                v_cols.push({
                    name: 'quantity',
                    label: '采购数量',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                v_cols.push({
                    name: 'shipquantity',
                    label: '已出运数量',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                v_cols.push({
                    name: 'colorname',
                    label: '颜色',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                v_cols.push({
                    name: 'packingsize',
                    label: '包装尺寸',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                console.log('importAddCols:2');
                console.log(v_cols);
                return v_graphqlFeild;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveData() {
                this.butLoading = true;
                this.freshcalculate();
                this.$refs.universalpage.saveInfo(true);
            },
            submitData() {
                this.butLoading = true;
                this.freshcalculate();
                this.$refs.universalpage.saveInfo();
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            getAllCost(v_allchildements) {
                var allcost = 0;
                for (var k = 0; k < v_allchildements.length; k++) {
                    if (v_allchildements[k]['name'] == 'cost') {
                        for (var k1 = 0; k1 < v_allchildements[k].rows.length; k1++) {
                            var costamount = Number(v_allchildements[k].rows[k1]['costamount']);
                            //var exchangerate = Number(v_allchildements[k].rows[k1]['exchangerate']);
                            if (!isNaN(costamount)) {
                                allcost = allcost + costamount;
                            }
                        }
                        break;
                    }
                }
                return allcost;
            },
            getAllVolume(productsdata) {
                var volumesummary = 0;
                for (var k2 = 0; k2 < productsdata.length; k2++) {
                    var tempnum2 = Number(productsdata[k2]['volume']);
                    var quantitynum2 = Number(productsdata[k2]['quantity']);
                    if (!isNaN(tempnum2) && !isNaN(quantitynum2)) {
                        volumesummary = volumesummary + tempnum2 * quantitynum2;
                    }
                }
                return volumesummary;
            },
            productCellEditInit(event, tablename, colconfigs) {
                console.log(event);
                console.log(tablename);
                console.log(colconfigs);
            },
            childCreateBefore(v_childname, v_newRow) {
                console.log('childCreateBefore....');
                console.log(v_childname);
                console.log(v_newRow);
                if (v_childname == 'cost') {
                    var allelements = this.$refs.universalpage.getAllMainElements();
                    var noshowelements = this.$refs.universalpage.getNoShowElements();
                    console.log(allelements);
                    console.log(noshowelements);
                    var purchaseExchangeRate = 0;
                    var purchaseCurrency = -1;
                    for (var k1 = 0; k1 < allelements.length; k1++) {
                        if (allelements[k1]['name'] == 'purchaseexchangerate') {
                            purchaseExchangeRate = Number(allelements[k1]['val']);
                        }
                        if (allelements[k1]['name'] == 'purchasecurrency') {
                            purchaseCurrency = Number(allelements[k1]['val']);
                        }
                    }
                    if (noshowelements['purchasecurrencyname']) {
                        v_newRow['currencyname'] = noshowelements['purchasecurrencyname'];
                    }
                    v_newRow['currency'] = purchaseCurrency + "";
                    v_newRow['exchangerate'] = purchaseExchangeRate;
                }
                return v_newRow;
            },
            cellEditCompleteExpande(tablename, field, currentrow, parentRow, allcurrentdata, allparentdata,
                childTableFooterObj, v_elements, v_alonechildelements, v_childelements) {
                console.log('cellEditCompleteExpandeMethod..');
                console.log(tablename);
                console.log(field);
                console.log(currentrow);
                console.log(parentRow);
                console.log(allcurrentdata);
                console.log(allparentdata);
                console.log(childTableFooterObj);
                console.log(v_elements);
                console.log(v_alonechildelements);
                console.log(v_childelements);

            },
            calculateVolumeProductPart(currentrow) {
                console.log('calculateVolumeProductPart..');
                var lengthnum = Number(currentrow['length']);
                var heightnum = Number(currentrow['height']);
                var widthnum = Number(currentrow['width']);
                var volumenum = 0;
                if (!isNaN(lengthnum) && !isNaN(heightnum) && !isNaN(widthnum)) {
                    volumenum = (lengthnum * heightnum * widthnum / this.volumeratio).toFixed(4);
                    currentrow['volume'] = volumenum;
                }
                return volumenum;
            },
            calculateCostProductPart(productsdata) {
                console.log('calculateCostProductPart..');
                console.log(productsdata);
                for (var k = 0; k < productsdata.length; k++) {
                    if (productsdata[k]['parts']) {
                        var volumenum = Number(productsdata[k]['volume']);
                        var sharingcostnum = Number(productsdata[k]['sharingcost']);
                        var fobpricenum = Number(productsdata[k]['fobprice']);
                        if (isNaN(sharingcostnum)) sharingcostnum = 0;
                        if (!isNaN(volumenum) && (!isNaN(sharingcostnum) || !isNaN(fobpricenum))) {
                            console.log('calculateCostProductPart..2...');
                            var sharingcostnum1 = 0;
                            var fobpricenum1 = 0;
                            for (var k1 = 0; k1 < productsdata[k]['parts'].length; k1++) {
                                productsdata[k]['parts'][k1]['sharingcost'] = 0;
                                productsdata[k]['parts'][k1]['fobprice'] = 0;
                                var volumenum1 = Number(productsdata[k]['parts'][k1]['volume']);
                                var matchingnum1 = Number(productsdata[k]['parts'][k1]['matching']);
                                if (k1 != productsdata[k]['parts'].length - 1) {
                                    if (!isNaN(volumenum1) && !isNaN(matchingnum1) && !isNaN(sharingcostnum)) {
                                        var sharingcostnum2 = (sharingcostnum * ((volumenum1 *
                                            matchingnum1) / volumenum)).toFixed(2);
                                        productsdata[k]['parts'][k1]['sharingcost'] = sharingcostnum2;
                                        sharingcostnum1 = sharingcostnum1 + Number(sharingcostnum2);
                                    }
                                    if (!isNaN(volumenum1) && !isNaN(matchingnum1) && !isNaN(fobpricenum)) {
                                        var fobpricenum2 = (fobpricenum * ((volumenum1 *
                                            matchingnum1) / volumenum)).toFixed(2);
                                        productsdata[k]['parts'][k1]['fobprice'] = fobpricenum2;
                                        fobpricenum1 = fobpricenum1 + Number(fobpricenum2);
                                    }

                                } else {
                                    if (k1 == productsdata[k]['parts'].length - 1) {
                                        console.log('calculateCostProductPart..3...');
                                        console.log(sharingcostnum);
                                        console.log(sharingcostnum1);
                                        console.log(fobpricenum);
                                        console.log(fobpricenum1);
                                        productsdata[k]['parts'][k1]['sharingcost'] = (sharingcostnum - sharingcostnum1)
                                            .toFixed(2);
                                        productsdata[k]['parts'][k1]['fobprice'] = (fobpricenum - fobpricenum1).toFixed(
                                            2);
                                    }
                                }
                            }
                        }
                    }
                }
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
                if (tablename != 'products' && tablename != 'cost') {
                    return;
                }
                if (tablename == 'products' && field == '') {
                    for (var k6 = 0; k6 < v_elements.length; k6++) {
                        if (v_elements[k6]['name'] == 'purchasecurrency') {
                            v_elements[k6]['val'] = rowdata['currency'];
                            this.$refs.universalpage.$refs.purchasecurrency.freshSelect(rowdata['currency']);
                        }
                    }
                }
                var productsdata = [];
                var purchaseExchangeRate = 0;
                var rateProfit = 0;
                var fOBExchangeRate = 0;
                for (var k1 = 0; k1 < v_elements.length; k1++) {
                    if (v_elements[k1]['name'] == 'purchaseexchangerate') {
                        purchaseExchangeRate = Number(v_elements[k1]['val']);
                    }
                    if (v_elements[k1]['name'] == 'rateprofit') {
                        rateProfit = Number(v_elements[k1]['val']);
                    }
                    if (v_elements[k1]['name'] == 'fobexchangerate') {
                        fOBExchangeRate = Number(v_elements[k1]['val']);
                    }
                }
                for (var i = 0; i < v_childelements.length; i++) {
                    if (v_childelements[i]['name'] == 'products') {
                        productsdata = v_childelements[i]['rows'];
                        break;
                    }
                }
                if (tablename == 'products' && field == 'quantity' && rowdata['parts']) {
                    var quantitynum = Number(rowdata['quantity']);
                    if (!isNaN(quantitynum)) {
                        for (var i1 = 0; i1 < rowdata['parts'].length; i1++) {
                            var matchingnum1 = Number(rowdata['parts'][i1]['matching']);
                            if (!isNaN(matchingnum1)) {
                                rowdata['parts'][i1]['quantity'] = matchingnum1 * quantitynum;
                            }
                        }
                    }
                }
                if (tablename == 'products' && field == '' && rowdata['parts']) {
                    var volumenum = 0;
                    for (var i2 = 0; i2 < rowdata['parts'].length; i2++) {
                        var matchingnum2 = Number(rowdata['parts'][i2]['matching']);
                        var volumenum2 = this.calculateVolumeProductPart(rowdata['parts'][i2]);
                        if (!isNaN(matchingnum2) && !isNaN(volumenum2)) {
                            console.log(volumenum + '+' + volumenum2 + '*' + matchingnum2);
                            volumenum = volumenum + volumenum2 * matchingnum2;
                        }
                    }
                    rowdata['volume'] = volumenum;
                }
                if (tablename == 'products' && rowdata['producttype'] != '2') {
                    this.productsvolumecalculate(rowdata);
                }
                if (tablename == 'cost') {
                    this.costcalculate(rowdata);
                }
                var volumesummary = this.getAllVolume(productsdata);
                var allcost = this.getAllCost(v_childelements);
                var sharingcostsum = 0;
                for (var k4 = 0; k4 < productsdata.length; k4++) {
                    if (k4 == productsdata.length - 1) {
                        productsdata[k4]['sharingcost'] = (allcost - sharingcostsum).toFixed(2);
                    } else {
                        this.productssharingcostcalculate(productsdata[k4], volumesummary, allcost);
                        sharingcostsum = sharingcostsum + Number(productsdata[k4]['sharingcost']);
                    }
                    this.productsfobpricecalculate(productsdata[k4], purchaseExchangeRate, rateProfit,
                        fOBExchangeRate);
                }
                this.calculateCostProductPart(productsdata);
            },
            freshcalculate() {
                var allelements = this.$refs.universalpage.getAllMainElements();
                var allchildelements = this.$refs.universalpage.getAllChildElements();
                var productsdata = [];
                var purchaseExchangeRate = 0;
                var rateProfit = 0;
                var fOBExchangeRate = 0;
                for (var k1 = 0; k1 < allelements.length; k1++) {
                    if (allelements[k1]['name'] == 'purchaseexchangerate') {
                        purchaseExchangeRate = Number(allelements[k1]['val']);
                    }
                    if (allelements[k1]['name'] == 'rateprofit') {
                        rateProfit = Number(allelements[k1]['val']);
                    }
                    if (allelements[k1]['name'] == 'fobexchangerate') {
                        fOBExchangeRate = Number(allelements[k1]['val']);
                    }
                }
                for (var i = 0; i < allchildelements.length; i++) {
                    if (allchildelements[i]['name'] == 'products') {
                        productsdata = allchildelements[i]['rows'];
                        break;
                    }
                }
                var volumesummary = this.getAllVolume(productsdata);
                var allcost = this.getAllCost(allchildelements);
                var sharingcostsum = 0;
                for (var k4 = 0; k4 < productsdata.length; k4++) {
                    if (k4 == productsdata.length - 1) {
                        productsdata[k4]['sharingcost'] = (allcost - sharingcostsum).toFixed(2);
                    } else {
                        this.productssharingcostcalculate(productsdata[k4], volumesummary, allcost);
                        sharingcostsum = sharingcostsum + Number(productsdata[k4]['sharingcost']);
                    }
                    this.productsfobpricecalculate(productsdata[k4], purchaseExchangeRate, rateProfit,
                        fOBExchangeRate);
                }
                this.calculateCostProductPart(productsdata);
                for (var n2 = 0; n2 < allchildelements.length; n2++) {
                    this.$refs.universalpage.sumAllField(allchildelements[n2]['name'], allchildelements[n2]['rows']);
                }
            },
            productsvolumecalculate(rowdata) {
                var lengthnum = Number(rowdata['length']);
                var heightnum = Number(rowdata['height']);
                var widthnum = Number(rowdata['width']);
                if (!isNaN(lengthnum) && lengthnum > 0 && !isNaN(heightnum) && heightnum > 0 && !isNaN(widthnum) &&
                    widthnum > 0) {
                    rowdata['volume'] = (lengthnum * heightnum * widthnum / this.volumeratio).toFixed(4);
                }
                console.log('productsvolumecalculate..' + rowdata['volume']);
            },
            costcalculate(rowdata) {
                var taxratenum = Number(rowdata['taxrate']);
                var invoiceamountnum = Number(rowdata['invoiceamount']);
                if (!isNaN(taxratenum) && !isNaN(invoiceamountnum)) {
                    rowdata['costamount'] = invoiceamountnum * (1 - taxratenum).toFixed(2);
                }
                console.log('costcalculate..' + rowdata['costamount']);
            },
            productssharingcostcalculate(rowdata, volumesummary, allcost) {
                var volume = Number(rowdata['volume']);
                var quantitynum = Number(rowdata['quantity']);
                rowdata['sharingcost'] = 0;
                if (!isNaN(volume) && !isNaN(quantitynum) && !isNaN(allcost)) {
                    if (quantitynum > 0 && volumesummary > 0 && allcost > 0) {
                        rowdata['sharingcost'] = (((volume * quantitynum) / volumesummary) * allcost).toFixed(2);
                    }
                }
                console.log('productssharingcostcalculate..' + rowdata['sharingcost']);
            },
            productsfobpricecalculate(rowdata, purchaseExchangeRate, rateProfit, fOBExchangeRate) {
                var quantitynum = Number(rowdata['quantity']);
                var unitprice = Number(rowdata['unitprice']);
                var sharingcost = Number(rowdata['sharingcost']);
                var taxrebates = Number(rowdata['taxrebates']);
                if (isNaN(taxrebates) || taxrebates == -1) {
                    taxrebates = 0;
                }
                if (isNaN(sharingcost)) {
                    sharingcost = 0;
                }
                if (isNaN(rateProfit)) {
                    rateProfit = 0;
                }
                console.log('quantitynum..' + quantitynum);
                console.log('unitprice..' + unitprice);
                console.log('sharingcost..' + sharingcost);
                console.log('taxrebates..' + taxrebates);
                console.log('purchaseExchangeRate..' + purchaseExchangeRate);
                console.log('rateProfit..' + rateProfit);
                console.log('fOBExchangeRate..' + fOBExchangeRate);
                rowdata['fobprice'] = 0;
                if (!isNaN(unitprice) && !isNaN(sharingcost) && !isNaN(quantitynum) && !isNaN(
                        purchaseExchangeRate) && !isNaN(rateProfit) && !isNaN(fOBExchangeRate)) {
                    if (fOBExchangeRate > 0 && quantitynum > 0) {
                        console.log("(((" + unitprice + "*" + purchaseExchangeRate + "/(" + taxrebates + "1)" + "+" +
                            sharingcost + "*" +
                            purchaseExchangeRate + "/" +
                            quantitynum +
                            ")" + "*" + "(1" + "+" + rateProfit + "))/" + fOBExchangeRate + ")");
                        rowdata['fobprice'] = (((unitprice * purchaseExchangeRate / (taxrebates + 1) + sharingcost *
                            purchaseExchangeRate / quantitynum) * (1 +
                            rateProfit)) / fOBExchangeRate).toFixed(2);
                    }
                }
                console.log('productsfobpricecalculate..' + rowdata['fobprice']);
            },
            loadFlowInfo() {
                requests.plat_requests.post(
                    '/calf/plat/FlowInfo?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        FlowGuid: 'Shipment',
                        BoxId: '',
                    }).then(res => {
                    console.log('loadFlowInfo...');
                    console.log(res);
                    if (res.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        this.flowId = retObj.FlowId;
                        this.startStateId = retObj.CurrentStateId;
                    }
                });
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements,
                v_childExpandeConfig) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                if (this.currentRow && this.currentRow['id'] == '-1') {
                    v_originalData['shipment']['storeid'] = sessionStorage.getItem("storeid") + '';
                    for (var k3 = 0; k3 < v_mainelements.length; k3++) {
                        if (v_mainelements[k3]['name'] == 'storeid') {
                            v_mainelements[k3]['val'] = sessionStorage.getItem("storeid") + '';
                            break;
                        }
                    }
                    for (var k6 = 0; k6 < this.pageJsonConfig['region_1']['left']['element'].length; k6++) {
                        if (this.pageJsonConfig['region_1']['left']['element'][k6]['default'] && this.pageJsonConfig[
                                'region_1']['left']['element'][k6]['default'] != '') {
                            for (var k5 = 0; k5 < v_mainelements.length; k5++) {
                                var region_1 = this.pageJsonConfig['region_1']['left']['element'][k6];
                                if (v_mainelements[k5]['name'] == region_1['name']) {
                                    v_mainelements[k5]['val'] = region_1['default'];
                                    break;
                                }
                            }
                        }
                    }
                    setTimeout(() => {
                        this.$refs.universalpage.$refs.storeid.freshSelect(v_originalData['shipment'][
                            'storeid'
                        ]);
                    }, 100);
                    for (var k2 = 0; k2 < v_childelements.length; k2++) {
                        if (v_childelements[k2]['name'] == 'products') {
                            v_childelements[k2]['rows'] = [];
                        }
                        if (v_childelements[k2]['name'] == 'cost') {
                            v_childelements[k2]['rows'] = [];
                        }
                    }
                } else {
                    for (var k = 0; k < v_mainelements.length; k++) {
                        if (v_mainelements[k]['name'] == 'purchasecurrency') {
                            this.purchasecurrencyChange(v_childelements, v_mainelements[k]['val'], this.currencyDict,
                                v_childExpandeConfig);
                        }
                        if (v_mainelements[k]['name'] == 'fobcurrency') {
                            this.fobcurrencyChange(v_childelements, v_mainelements[k]['val'], this.currencyDict,
                                v_childExpandeConfig);
                        }
                    }
                }
            },
            storagewarehouseChange(v_childelements, v_val, v_dictsource, v_allmainelements) {
                console.log('storagewarehouseChange....');
                console.log(v_childelements);
                console.log(v_val);
                console.log(v_dictsource);
                console.log(v_allmainelements);
                var day = 0;
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        day = Number(v_dictsource[i3].other);
                        break;
                    }
                }
                console.log(day);
                if (!isNaN(day)) {
                    let today = new Date();
                    today.setDate(today.getDate() + day);
                    for (var k1 = 0; k1 < v_allmainelements.length; k1++) {
                        if (v_allmainelements[k1]['name'] == 'expectedinstorage') {
                            // var year = today.getFullYear();
                            // var month = ('0' + (today.getMonth() + 1)).slice(-2);
                            // var day = ('0' + today.getDate()).slice(-2);
                            console.log(today.format("yyyy-MM-dd"));
                            v_allmainelements[k1]['val'] = today.format("yyyy-MM-dd");
                            break;
                        }
                    }
                }
            },
            fobcurrencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'fobprice') {
                            v_childelements[i].columns[i2].header = 'FOB单价(' + ename + ')';
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'fobprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = 'FOB单价(' + ename + ')';
                            break;
                        }
                    }

                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'fobprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = 'FOB单价(' + ename + ')';
                            break;
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
                this.freshcalculate();
            },
            purchasecurrencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var isbreak = false;
                var loopindex = 0;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'unitprice') {
                            v_childelements[i].columns[i2].header = '产品单价(' + ename + ')';
                            loopindex++;
                        }
                        if (v_childelements[i].columns[i2].field == 'sharingcost') {
                            v_childelements[i].columns[i2].header = '分摊费用(' + ename + ')';
                            loopindex++;
                        }
                        if (loopindex == 2) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                console.log('purchasecurrencyChange...');
                if (v_childExpandeConfig) {
                    console.log(v_childExpandeConfig);
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'unitprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '产品单价(' + ename + ')';
                        }
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'sharingcost') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '分摊费用(' + ename + ')';
                        }
                    }

                } else {
                    console.log(this.childExpandeConfig);
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'unitprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '产品单价(' + ename + ')';
                        }
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'sharingcost') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '分摊费用(' + ename + ')';
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
                this.freshcalculate();
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            'AppFooter': AppFooter,
        }
    }
</script>